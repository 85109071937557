import '../styles/index.scss';
import React from 'react';
// import { Link } from 'gatsby';
// import Header from '../components/header';
import Header from '../components/HeaderConfirm';
import Footer from '../components/Footer';
import SEO from '../components/seo';
// import Banner from '../components/GoodStickersBanner';
// import NewsLetter from '../components/NewsLetter';

const Privacy = () => {
	return (
		<React.Fragment>
			<Header />
			<SEO title="Privacy Policy" />
			<div className="privacy-container">
				<div className="privacy-section-container">
					<div className="privacy-title">Privacy</div>

					<div className="privacy-section-description">
						This Privacy Policy describes how your personal information is collected, used, and shared when
						you visit or make a purchase from catbear.love (the “Site”).{' '}
					</div>
					{/* <div className="privacy-section-description">By visiting our site and/or purchasing something from us, you engage in our “Service” and agree to be bound by the following privacy and conditions (“privacy of Service”, “privacy”), including those additional privacy and conditions and policies referenced herein and/or available by hyperlink. These privacy of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.</div>
        <div className="privacy-section-description">Please read these privacy of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these privacy of Service. If you do not agree to all the privacy and conditions of this agreement, then you may not access the website or use any services. If these privacy of Service are considered an offer, acceptance is expressly limited to these privacy of Service.</div>
        <div className="privacy-section-description">Any new features or tools which are added to the current store shall also be subject to the privacy of Service. You can review the most current version of the privacy of Service at any time on this page. We reserve the right to update, change or replace any part of these privacy of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</div>
        <div className="privacy-section-description">Our store uses Shopify Inc. to accept payments. They provide us with the online e-commerce platform that allows us to sell our products and services to you. </div> */}
					{/* <div className="privacy-section-description"></div>
        <div className="privacy-section-description"></div>
        <div className="privacy-section-description"></div>
        <div className="privacy-section-description"></div> */}
				</div>
				<div className="privacy-section-container">
					<div className="privacy-section-title">Section 1 - Online store privacy</div>

					<div className="privacy-section-description">
						When you visit the Site, we automatically collect certain information about your device,
						including information about your web browser, IP address, time zone, and some of the cookies
						that are installed on your device. Additionally, as you browse the Site, we collect information
						about the individual web pages or products that you view, what websites or search terms referred
						you to the Site, and information about how you interact with the Site. We refer to this
						automatically-collected information as “Device Information”.{' '}
					</div>
					<div className="privacy-section-description">
						We collect Device Information using the following technologies:
						<br />
						- “Cookies” are data files that are placed on your device or computer and often include an
						anonymous unique identifier. For more information about cookies, and how to disable cookies,
						visit http://www.allaboutcookies.org.
						<br />
						- “Log files” track actions occurring on the Site, and collect data including your IP address,
						browser type, Internet service provider, referring/exit pages, and date/time stamps.
						<br />
						- “Web beacons”, “tags”, and “pixels” are electronic files used to record information about how
						you browse the Site.
					</div>
					<div className="privacy-section-description">
						Additionally when you make a purchase or attempt to make a purchase through the Site, we collect
						certain information from you, including your name, billing address, shipping address, payment
						information (including credit card numbers), email address, and phone number. We refer to this
						information as “Order Information”.
					</div>
					<div className="privacy-section-description">
						When we talk about “Personal Information” in this Privacy Policy, we are talking both about
						Device Information and Order Information.
					</div>
					{/* <div className="privacy-section-description"></div>
        <div className="privacy-section-description"></div>
        <div className="privacy-section-description"></div>
        <div className="privacy-section-description"></div>
        <div className="privacy-section-description"></div>
        <div className="privacy-section-description"></div> */}
				</div>

				<div className="privacy-section-container">
					<div className="privacy-section-title">How do we use your personal information?</div>
					<div className="privacy-section-description">
						We use the Order Information that we collect generally to fulfill any orders placed through the
						Site (including processing your payment information, arranging for shipping, and providing you
						with invoices and/or order confirmations). Additionally, we use this Order Information to:
						<br />
						- Communicate with you;
						<br />
						- Screen our orders for potential risk or fraud; and
						<br />
						- When in line with the preferences you have shared with us, provide you with information or
						advertising relating to our products or services.
						<br />
					</div>
					<div className="privacy-section-description">
						We use the Device Information that we collect to help us screen for potential risk and fraud (in
						particular, your IP address), and more generally to improve and optimize our Site (for example,
						by generating analytics about how our customers browse and interact with the Site, and to assess
						the success of our marketing and advertising campaigns).
					</div>
				</div>
				<div className="privacy-section-container">
					<div className="privacy-section-title">Sharing your personal Information </div>
					<div className="privacy-section-description">
						We share your Personal Information with third parties to help us use your Personal Information,
						as described above. For example, we use Shopify to power our online store--you can read more
						about how Shopify uses your Personal Information here: https://www.shopify.com/legal/privacy.
					</div>
					<div className="privacy-section-description">
						{' '}
						We also use Google Analytics. We use Google Analytics for aggregated, anonymized website traffic
						analysis. In order to track your session usage, Google drops a cookie (_ga) with a
						randomly-generated ClientID in your browser. This ID is anonymized and contains no identifiable
						information like email, phone number, name, etc. We also send Google your IP Address. We use GA
						to track aggregated website behavior, such as what pages you looked at, for how long, and so on.
						This information is important to us for improving the user experience and determining site
						effectiveness. If you would like to access what browsing information we have - or ask us to
						delete any GA data - please delete your _ga cookies, reach out to us via this form, and/or
						install the Google Analytics Opt-Out Browser Add-On.
					</div>
					<div className="privacy-section-description">
						Finally, we may also share your Personal Information to comply with applicable laws and
						regulations, to respond to a subpoena, search warrant or other lawful request for information we
						receive, or to otherwise protect our rights.
					</div>
				</div>
				<div className="privacy-section-container">
					<div className="privacy-section-title">Behavioral advertising</div>
					<div className="privacy-section-description">
						As described above, we use your Personal Information to provide you with targeted advertisements
						or marketing communications we believe may be of interest to you. For more information about how
						targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”)
						educational page at
						http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.{' '}
					</div>
					<div className="privacy-section-description">
						You can opt out of targeted advertising by using the links below:
						<br />
						- Facebook: https://www.facebook.com/settings/?tab=ads
						<br />
						- Google: https://www.google.com/settings/ads/anonymous
						<br />
						- Bing: https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
						<br />
						Additionally, you can opt out of some of these services by visiting the Digital Advertising
						Alliance’s opt-out portal at: http://optout.aboutads.info/.
					</div>
				</div>
				<div className="privacy-section-container">
					<div className="privacy-section-title">Do not track </div>
					<div className="privacy-section-description">
						Please note that we do not alter our Site’s data collection and use practices when we see a Do
						Not Track signal from your browser.
					</div>
				</div>
				<div className="privacy-section-container">
					<div className="privacy-section-title">Your rights </div>
					<div className="privacy-section-description">
						If you are a European resident, you have the right to access personal information we hold about
						you and to ask that your personal information be corrected, updated, or deleted. If you would
						like to exercise this right, please contact us through the contact information below.{' '}
					</div>
					<div className="privacy-section-description">
						Additionally, if you are a European resident we note that we are processing your information in
						order to fulfill contracts we might have with you (for example if you make an order through the
						Site), or otherwise to pursue our legitimate business interests listed above. Additionally,
						please note that your information will be transferred outside of Europe, including to Canada and
						the United States.{' '}
					</div>
				</div>
				<div className="privacy-section-container">
					<div className="privacy-section-title">Data retention </div>
					<div className="privacy-section-description">
						When you place an order through the Site, we will maintain your Order Information for our
						records unless and until you ask us to delete this information.{' '}
					</div>
				</div>
				<div className="privacy-section-container">
					<div className="privacy-section-title">Changes</div>
					<div className="privacy-section-description">
						We may update this privacy policy from time to time in order to reflect, for example, changes to
						our practices or for other operational, legal or regulatory reasons.{' '}
					</div>
				</div>
				<div className="privacy-section-container">
					<div className="privacy-section-title">Minors</div>
					<div className="privacy-section-description">
						The Site is not intended for individuals under the age of 18.
					</div>
				</div>
				<div className="privacy-section-container">
					<div className="privacy-section-title">Contact Us</div>
					<div className="privacy-section-description">
						For more information about our privacy practices, if you have questions, or if you would like to
						make a complaint, please contact us by e‑mail at{' '}
						<span style={{ color: '#22b2f2' }}>hello@catbear.love</span>.
					</div>
				</div>

				{/* <NewsLetter />
    <Banner align="center" color="#3EC3B5" /> */}
			</div>
			<Footer />
		</React.Fragment>
	);
};

export default Privacy;
